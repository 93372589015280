<template>
  <div class="no-pass-audit-box">
    <van-nav-bar :title="title" />
    <div class="no-pass-audit-con">
      <div class="no-pass-img-box">
        <div class="no-pass-audit">
          <img src="../../assets/positionMgt/no_pass_audit.png" alt />
          <p>审核不通过</p>
        </div>
      </div>

      <div class="no-pass-reason-box">
        <div class="no-pass-reason">
          <h5>审核失败原因</h5>
          <p>{{ approveInfos }}</p>
        </div>
      </div>
      <van-form @submit="onSubmit">
        <div class="list-box">
          <p>基本信息</p>

          <div class="filed-box">
            <van-field
              v-model="userForm.userSelfhelpVO.userName"
              name="姓名"
              label="姓名"
              required
              placeholder="请输入姓名"
              :rules="[{ required: true, message: '' }]"
            />
          </div>
          <div class="filed-box">
            <van-field
              v-model="genderName"
              label="性别"
              required
              clearable
              placeholder="请选择性别"
              readonly
              :rules="[{ required: true, message: '' }]"
              @click="genderPicker = true"
            >
              <template #button>
                <van-icon v-if="genderPicker" name="arrow-up" />
                <van-icon v-else name="arrow-down" />
              </template>
            </van-field>
            <van-popup v-model="genderPicker" round position="bottom">
              <van-picker
                show-toolbar
                :columns="genderColumns"
                @cancel="genderPicker = false"
                @confirm="genderConfirm"
              >
                <template #option="item">
                  <p style="text-align: center">{{ item.label }}</p>
                </template>
              </van-picker>
            </van-popup>
          </div>
          <div class="filed-box">
            <van-field
              v-model="userForm.selfhelpCompanyApproveVO.companyName"
              name="公司名称"
              label="公司名称"
              readonly
              required
              placeholder="请输入公司名称"
              :rules="[{ required: true, message: '' }]"
              @click="companyPicker = true"
            >
              <template #button>
                <van-icon v-if="companyPicker" name="arrow-up" />
                <van-icon v-else name="arrow-down" />
              </template>
            </van-field>
            <van-popup
              v-model="companyPicker"
              closeable
              close-icon="close"
              round
              position="bottom"
              :style="{ height: '50%' }"
            >
              <van-search
                show-action
                v-model="searchCompany"
                @search="getCompanyList"
                placeholder="请输入搜索的公司"
                style="margin-top: 10px"
              >
                <template #action>
                  <div @click="getCompanyList">搜索</div>
                </template>
              </van-search>
              <div
                v-if="isNew"
                class="no-company"
                @click="createCompany(searchCompany)"
              >选择并使用“{{ searchCompany }}”为新公司</div>
              <van-list v-if="companyColumns.length">
                <van-cell
                  v-for="(item, i) in companyColumns"
                  :key="i"
                  :title="item.companyName"
                  @click="companyConfirm(item)"
                />
              </van-list>
            </van-popup>
          </div>
          <div class="filed-box">
            <van-field
              v-model="userForm.userSelfhelpVO.email"
              name="邮箱地址（企业邮箱）"
              label="邮箱地址（企业邮箱）"
              required
              placeholder="邮箱地址（企业邮箱）"
              :rules="[{ required: true, message: '' }]"
            />
          </div>
          <div class="filed-box">
            <van-field
              v-model="userForm.companyDeptVO.deptName"
              name="注册用户所在部门"
              label="注册用户所在部门"
              required
              placeholder="注册用户所在部门"
              readonly
              :disabled="!genderName"
              :rules="[{ required: true, message: '' }]"
              @click="deptShow"
            >
              <template #button>
                <van-icon v-if="deptPicker" name="arrow-up" />
                <van-icon v-else name="arrow-down" />
              </template>
            </van-field>
            <van-popup v-model="deptPicker" round position="bottom" :style="{ height: '50%' }">
              <div class="no-dept" @click="createDept">新增部门</div>
              <van-picker
                show-toolbar
                v-if="deptColumns.length"
                :columns="deptColumns"
                @cancel="deptPicker = false"
                @confirm="deptConfirm"
              >
                <template #option="item">
                  <p style="text-align: center">{{ item.deptName }}</p>
                </template>
              </van-picker>
            </van-popup>
          </div>
        </div>

        <div class="list-box">
          <p>上传证件</p>
          <van-uploader
            v-model="fileList"
            :max-size="1024 * 1024"
            accept="image/jpg, image/png"
            :after-read="afterRead"
            :max-count="1"
            @oversize="onOversize"
          >
            <div class="uploader-btn">上传证件</div>
          </van-uploader>
          <span>
            上传的图片为在职证明或营业执照，仅支持
            <i>jpg、png</i>，大小为
            <i>1MB</i>
          </span>
        </div>
        <div class="btn-box">
          <van-button plain round block type="info" @click.prevent="goLogin">切换账号</van-button>
          <van-button round block type="info" native-type="submit">重新提交申请</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'noPassAudit',
  data() {
    return {
      title: '审核不通过',
      approveInfos: '',
      genderName: '',
      genderPicker: false,
      genderColumns: [
        { label: '男', value: 'MALE' },
        { label: '女', value: 'FEMALE' },
      ],

      userForm: {
        companyDeptVO: {
          deptName: null,
        },
        companyVO: {
          nameCn: null,
          nameEn: null,
          companyType: null,
          industryType: null,
          scaleType: null,
          financingType: null,
          projectOwer: null,
          areaId: null,
          address: null,
          longitude: null,
          latitude: null,
          startup: null,
          avatarUrl: null,
          description: null,
          benefit: null,
          hrCount: null,
          jobCount: null,
          expiryTime: null,
          cooperatingState: null,
          resumeRecommendType: null,
          resumeRecommendAddress: null,
          salaryComposition: null,
          calculationMethod: null,
          interviewFlow: null,
          expirationDate: null,
          level: null,
          areaName: null,
          creatorId: null,
          creatorName: null,
          updatorId: null,
          updatorName: null,
          state: 'ACTIVE',
          positionNameList: null,
          positionCounts: null,
          adressDetail: null,
          effectiveEnd: null,
          positionNames: null,
          effectiveEndList: null,
          postionValidCounts: null,
          positionTheStatisticsNumber: null,
          hunterCount: null,
          resumeCount: null,
          interviewCandidates: null,
          numberOfEmployed: null,
          interviewChance: null,
          singleRate: null,
          guaranteedRate: null,
          projectOwerName: null,
          recentPublishTime: null,
          levelName: null,
        },
        selfhelpCompanyApproveVO: {
          proveEnclosure: [],
          companyId: null, //公司ID
        },
        userSelfhelpVO: {
          gender: null, // 性别
          email: null, //邮箱
          deptId: null, //部门ID
          position: null, //所以岗位
        },
        // other中的数据是不传往后台的,只为了表单验证和重置使用
        other: {
          emailCaptcha: null, // 邮箱验证码(用于表单较验)
        },
      },
      fileList: [],
      companyPicker: false,
      searchCompany: '',
      companyColumns: [],
      loading: false,
      finished: false,
      deptPicker: false,
      deptColumns: [],
      searchDept: '',
      isNew: false,
      isNewDept: false,
    };
  },
  async mounted() {
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      let urlPath = null;
      switch (back.data.approveCodeEnum) {
        case 'UN_LINK':
          urlPath = '/noLegalize';
          break;
        case 'SECONED_WAITTING':
          urlPath = '/auditing';
          break;
        case 'WAITING':
          urlPath = '/auditing';
          break;
        case 'APPROVE_PASS':
          urlPath = '/passAudit';
          break;
        case 'PROCESS_DONE':
          urlPath = '/positionMgt/index';
          break;
        case 'COMPANY_STOP':
          urlPath = '/login';
          localStorage.setItem('userInfo', '');
          localStorage.setItem('accessToken', '');
          this.$toast('公司被禁用');
          break;
        case 'STOP':
          urlPath = '/login';
          localStorage.setItem('userInfo', '');
          localStorage.setItem('accessToken', '');
          this.$toast('账号禁用');
          break;
      }
      // 登录成功后跳转到主页
      if (back.data.approveCodeEnum === 'PROCESS_DONE') {
        this.SET_TABBARACTIVE(5);
      }
      this.$router.push({ path: urlPath });
      Object.assign(this.userForm, back.data);
      let userInfo = back.data;
      this.genderColumns.forEach((el) => {
        if (el.value === userInfo.userSelfhelpVO.gender) {
          this.genderName = el.label;
        }
      });
      this.userForm.companyDeptVO.deptName =
        userInfo.companyDeptVO.deptName || '';
      this.userForm.selfhelpCompanyApproveVO.companyName =
        userInfo.companyVO.nameCn || '';
      this.searchCompany = userInfo.companyVO.nameCn;
      this.getCompanyList(userInfo.companyVO.nameCn);
      this.approveInfos =
        userInfo.selfhelpCompanyApproveVO.approveInfos || '';
      this.fileList = [
        { url: userInfo.selfhelpCompanyApproveVO.proveEnclosure },
      ];
    }
  },
  watch: {
    'userForm.selfhelpCompanyApproveVO.companyId': {
      handler(N) {
        if (N) {
          this.getDeptList(N);
        }
      },
      immediate: true,
      deep: true,
    },
    searchCompany: {
      handler(N) {
        if (N) {
          this.isNew = false;
        }
      },
      deep: true,
    },
    companyColumns: {
      handler(N) {
        if (N.length === 0) {
          this.isNew = true;
        }
      },
      deep: true,
    },
    deptColumns: {
      handler(N) {
        if (N.length === 0) {
          this.isNewDept = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('app', ['SET_TABBARACTIVE']),
    onClickLeft() {
      this.$router.go(-1);
    },
    //切换登陆
    goLogin() {
      localStorage.setItem('userInfo', '');
      localStorage.setItem('accessToken', '');
      this.$router.push('/login')
    },
    genderConfirm(item) {
      this.genderName = item.label;
      this.userForm.userSelfhelpVO.gender = item.value;
      this.genderPicker = false;
    },
    companyConfirm(item) {
      this.userForm.selfhelpCompanyApproveVO.companyName = item.companyName;
      this.userForm.selfhelpCompanyApproveVO.companyId = item.id;
      this.companyPicker = false;
    },
    deptConfirm(item) {
      this.userForm.companyDeptVO.deptName = item.deptName;
      this.userForm.userSelfhelpVO.deptId = item.id;
      this.deptPicker = false;
    },
    getCompanyList() {
      this.$http
        .get('/selfhelpOfficialAccounts/companies', {
          companyName: this.searchCompany,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data && res.data.length) {
              this.companyColumns = res.data || [];
            } else {
              this.companyColumns = [];
            }
          }
        });
    },
    createCompany(name) {
      this.$router.push({
        path: '/positionMgt/companyEdit',
        query: { name: name },
      });
    },
    deptShow() {
      if (this.userForm.selfhelpCompanyApproveVO.companyId) {
        this.deptPicker = true;
      } else {
        this.$toast('请先选择公司');
      }
    },
    getDeptList(id) {
      this.$http
        .get('/selfhelpOfficialAccounts/companyDept', { companyId: id })
        .then((res) => {
          if (res.code === 200) {
            this.deptColumns = res.data || [];
            // this.isNewDept = res.data.length === 0;
          }
        });
    },
    createDept() {
      this.$router.push({
        path: '/myHome/deptAdd',
        query: {
          companyId: this.userForm.selfhelpCompanyApproveVO.companyId,
        },
      });
    },
    // bae64转文件对象
    dataURLtoFileFun(dataurl, filename) {
      // 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    afterRead() {
      const fromData = new FormData();
      fromData.append(
        'files',
        this.dataURLtoFileFun(
          this.fileList[0].content,
          this.fileList[0].file.name
        )
      );
      this.$http
        .postUpload(
          '/selfhelpOfficialAccounts/relation/upload/APPROVE',
          fromData
        )
        .then((res) => {
          if (res.code === 200) {
            this.userForm.selfhelpCompanyApproveVO.proveEnclosure = res.data;
          }
        });
    },
    onOversize(file) {
      console.log(file);
      this.$toast('文件大小不能超过 1MB');
    },
    onSubmit() {
      if (this.fileList.length < 1) {
        this.$toast('未上传图片');
        return;
      }
      this.$http
        .post('/selfhelpOfficialAccounts/relation', this.userForm)
        .then(async (res) => {
          if (res.code === 200) {
            this.$toast('提交成功，请等待审核结果');
            let back = await this.$http.get(
              '/selfhelpOfficialAccounts/relation'
            );
            if (back.code === 200) {
              localStorage.setItem('userInfo', JSON.stringify(back.data));
              this.$router.push('/auditing');
            }
          } else {
            this.$toast(res.message || '提交失败');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-pass-audit-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .no-pass-audit-con {
    display: flex;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    .no-pass-img-box {
      width: 100%;
      .no-pass-audit {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        img {
          height: 90px;
          width: 90px;
        }
        p {
          margin-top: 8px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
      }
    }

    .no-pass-reason-box {
      width: 100%;
      margin-top: 20px;
      padding: 0 15px;
      .no-pass-reason {
        background: #fef9f9;
        border-radius: 4px;
        padding: 15px;
        h5 {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f44336;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f44336;
        }
      }
    }
    .van-form {
      flex: 1;
    }

    .list-box {
      padding: 0 16px;
      margin: 20px 0 40px 0;
      > p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .filed-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebedf0;
        > .van-cell {
          flex: 1;
        }
        > .van-cell::after {
          border-bottom: none;
        }
        img {
          width: 77px;
          height: 23px;
        }
        ::v-deep .van-field--disabled .van-field__label {
          color: #646566;
        }
        ::v-deep .van-field__value input {
          text-align: right;
        }
        ::v-deep .van-popup__close-icon--top-right {
          top: 2px;
          right: 6px;
        }
        .no-company {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          padding: 10px 16px;
          overflow: hidden;
          font-size: 14px;
          line-height: 24px;
          background-color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #206cfe;
        }
        .no-dept {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          padding: 10px 16px;
          overflow: hidden;
          font-size: 14px;
          line-height: 24px;
          background-color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #206cfe;
          justify-content: center;
        }
      }
      .van-search .van-cell {
        padding: 5px 8px 5px 0;
        ::v-deep .van-field__value input {
          text-align: initial;
        }
      }
      .van-uploader {
        width: 100%;
      }
      ::v-deep .van-uploader__input-wrapper {
        width: 100%;
      }
      .uploader-btn {
        height: 45px;
        line-height: 45px;
        background: #fafafa;
        border-radius: 4px;
        border: 1px dashed #cccccc;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        i {
          color: #f44336;
          font-style: normal;
        }
      }
    }

    .btn-box {
      margin-top: 40px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      > *{
        margin: 0 10px;
      }
    }
  }
}
</style>
